<template>
  <v-col
    cols="12"
    sm="6"
    :md="2"
    :offset-md=value.offset
    offset-sm="0"
  >
    <v-img
      :src="require('@/assets/certificacion/' + value.image +'')"
      height="80"
      width="100%"
      max-height="80"
      contain
    />
  </v-col>
</template>

<script>
  export default {
    name: 'CertificacionesCard',
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>

<style scoped>

</style>
